import React from 'react'

import { Table, Button } from "react-bootstrap";


function MakOrganises() {
    return (
        <div>

            <div className="album py-1">
                <div className="container">
                    <center><h3 style={{color:"darkBlue"}} className='mb-2'><b>Expeditions</b></h3>
                        <p>MAK has successfully completed the following mountaineering expeditions.</p>
                    </center>
                    <hr />
                </div>
            </div>
           


            <div className="album py-1">
                <div className="container">

                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th> <center style={{ color: "maroon" }}>SL.</center></th>
                                <th><center style={{ color: "maroon" }}> Name of the peaks and its height</center></th>
                                <th><center style={{ color: "maroon" }}>Year</center></th>
                                <th><center style={{ color: "maroon" }}> Result</center></th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td > <center className='mb-2 mt-2'>1</center></td>
                                <td><center className='mb-2 mt-2'>Thelu (6001 Mtr.) & Koteswar (6059 Mtr.)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>1987</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>



                            </tr>

                            <tr >
                                <td > <center className='mb-2 mt-2'>2</center></td>
                                <td><center className='mb-2 mt-2'>Gangstang (6161 Mtr.) </center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>1990</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>



                            </tr>
                            <tr >
                                <td > <center className='mb-2 mt-2'>3</center></td>
                                <td><center className='mb-2 mt-2'>Baljuri (5844 Mtr.)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>1991</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>



                            </tr>
                            <tr >
                                <td > <center className='mb-2 mt-2'>4</center></td>
                                <td><center className='mb-2 mt-2'>Un-named Peak (Seta Glacier) (6352 Mtr.)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>1992</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>

                            </tr>


                            <tr >
                                <td > <center className='mb-2 mt-2'>5</center></td>
                                <td><center className='mb-2 mt-2'>Srikailash (6932 Mtr.)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>1993</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>

                            </tr>


                            <tr >
                                <td > <center className='mb-2 mt-2'>6</center></td>
                                <td><center className='mb-2 mt-2'>Tharkot (6099 Mtr.) & Bhanoti (5638 Mtr.)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>1994</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>

                            </tr>


                            <tr >
                                <td > <center className='mb-2 mt-2'>7</center></td>
                                <td><center className='mb-2 mt-2'>CB -11 (6044 Mtr.)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>1995</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>

                            </tr>

                            <tr >
                                <td > <center className='mb-2 mt-2'>8</center></td>
                                <td><center className='mb-2 mt-2'>Kamet (7756 Mtr..)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>1996</center></td>
                                <td><center className='mb-2 mt-2'>Unsuccessful</center></td>

                            </tr>

                            <tr >
                                <td > <center className='mb-2 mt-2'>9</center></td>
                                <td><center className='mb-2 mt-2'>Kamet (7756 Mtr..)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>1997</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>

                            </tr>
                            <tr >
                                <td > <center className='mb-2 mt-2'>10</center></td>
                                <td><center className='mb-2 mt-2'>Chowkhamba-I  (7138 Mtr.)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>1998</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>
                            </tr>
                            
                            <tr >
                                <td > <center className='mb-2 mt-2'>11</center></td>
                                <td><center className='mb-2 mt-2'>Bhrigupanth (6772Mtr)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>1999</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>
                            </tr>

                            <tr >
                                <td > <center className='mb-2 mt-2'>12</center></td>
                                <td><center className='mb-2 mt-2'>Nandakot (6858 Mtr.)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2000</center></td>
                                <td><center className='mb-2 mt-2'>Unsuccessful</center></td>
                            </tr>

                            <tr >
                                <td > <center className='mb-2 mt-2'>13</center></td>
                                <td><center className='mb-2 mt-2'>Shiva (6140 Mtr.)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2002</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>
                            </tr>


                            <tr >
                                <td > <center className='mb-2 mt-2'>14</center></td>
                                <td><center className='mb-2 mt-2'>Deotibba (6000 Mtr.)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2003</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>
                            </tr>


                            <tr >
                                <td > <center className='mb-2 mt-2'>15</center></td>
                                <td><center className='mb-2 mt-2'>Indrasan (6221 Mtr.)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2004</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>
                            </tr>


                            <tr >
                                <td > <center className='mb-2 mt-2'>16</center></td>
                                <td><center className='mb-2 mt-2'>Shivling (6543 Mtr.)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2005</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>
                            </tr>
                            <tr >
                                <td > <center className='mb-2 mt-2'>17</center></td>
                                <td><center className='mb-2 mt-2'>Panwalidwar (6663 Mtr.)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2006</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>
                            </tr>

                            <tr >
                                <td > <center className='mb-2 mt-2'>18</center></td>
                                <td><center className='mb-2 mt-2'>Rubal Kang (6185 Mtr.) & Kulu Makalu ( 6349 Mtr.)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2007</center></td>
                                <td><center className='mb-2 mt-2'>Climed Rubal Kang</center></td>
                            </tr>


                            <tr >
                                <td > <center className='mb-2 mt-2'>19</center></td>
                                <td><center className='mb-2 mt-2'>Thalay Sagar (6904 Mtr.)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2008</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>
                            </tr>

                            <tr >
                                <td > <center className='mb-2 mt-2'>20</center></td>
                                <td><center className='mb-2 mt-2'>Changuch (6322 Mtr.)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2009</center></td>
                                <td><center className='mb-2 mt-2'>Unsuccessful (Crossed Traill's Pass)</center></td>
                            </tr>

                            <tr >
                                <td > <center className='mb-2 mt-2'>21</center></td>
                                <td><center className='mb-2 mt-2'>Everest (8848 Mtr.)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2010</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>
                            </tr>

                            <tr >
                                <td > <center className='mb-2 mt-2'>22</center></td>
                                <td><center className='mb-2 mt-2'>Kanchenjunga (8586 Mtr.)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2011</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>
                            </tr>
                            <tr >
                                <td > <center className='mb-2 mt-2'>23</center></td>
                                <td><center className='mb-2 mt-2'>Annapurna (8091 Mtr.)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2012</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>
                            </tr>
                            <tr >
                                <td > <center className='mb-2 mt-2'>24</center></td>
                                <td><center className='mb-2 mt-2'>Dharamsura (6451 Mtr.) </center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2012</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>
                            </tr>
                            <tr >
                                <td > <center className='mb-2 mt-2'>25</center></td>
                                <td><center className='mb-2 mt-2'>Dhaulagiri (8167 Mtr.) </center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2013</center></td>
                                <td><center className='mb-2 mt-2'>Unsuccessful </center></td>
                            </tr>
                            <tr >
                                <td > <center className='mb-2 mt-2'>26</center></td>
                                <td><center className='mb-2 mt-2'>Nun (7135  Mtr.) </center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2014</center></td>
                                <td><center className='mb-2 mt-2'>Unsuccessful </center></td>
                            </tr>

                            <tr >
                                <td > <center className='mb-2 mt-2'>27</center></td>
                                <td><center className='mb-2 mt-2'>Nun (7135  Mtr.) </center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2015</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>
                            </tr>
                            <tr >
                                <td > <center className='mb-2 mt-2'>28</center></td>
                                <td><center className='mb-2 mt-2'>Changabang (6864 Mtr.) </center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2016</center></td>
                                <td><center className='mb-2 mt-2'>Unsuccessful </center></td>
                            </tr>
                            <tr >
                                <td > <center className='mb-2 mt-2'>29</center></td>
                                <td><center className='mb-2 mt-2'>Trisul (7120 Mtr.)  </center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2017</center></td>
                                <td><center className='mb-2 mt-2'>Unsuccessful</center></td>
                            </tr>
                            <tr >
                                <td > <center className='mb-2 mt-2'>30</center></td>
                                <td><center className='mb-2 mt-2'>Black (6386 Mtr.) </center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2017</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>
                            </tr>
                            <tr >
                                <td > <center className='mb-2 mt-2'>31</center></td>
                                <td><center className='mb-2 mt-2'>Saser Kangri IV (7416 Mtr.)</center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2018</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>
                            </tr>

                            <tr >
                                <td > <center className='mb-2 mt-2'>32</center></td>
                                <td><center className='mb-2 mt-2'>Debachen (6265 Mtr.) </center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2019</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>
                            </tr>

                            <tr >
                                <td > <center className='mb-2 mt-2'>33</center></td>
                                <td><center className='mb-2 mt-2'>Everest (8848.86 Mtr.) </center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2021</center></td>
                                <td><center className='mb-2 mt-2'>Unsuccessful</center></td>
                            </tr>

                            <tr >
                                <td > <center className='mb-2 mt-2'>34</center></td>
                                <td><center className='mb-2 mt-2'>Ramjak (6318 Mtr.) </center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2021</center></td>
                                <td><center className='mb-2 mt-2'>Unsuccessful</center></td>
                            </tr>

                            <tr >
                                <td > <center className='mb-2 mt-2'>35</center></td>
                                <td><center className='mb-2 mt-2'>Manaslu (8163 Mtr.) </center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2022</center></td>
                                <td><center className='mb-2 mt-2'>Unsuccessful</center></td>
                            </tr>

                            <tr >
                                <td > <center className='mb-2 mt-2'>36</center></td>
                                <td><center className='mb-2 mt-2'>Gorichen (6488 Mtr.)  </center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2023</center></td>
                                <td><center className='mb-2 mt-2'>Unsuccessful</center></td>
                            </tr>


                            <tr >
                                <td > <center className='mb-2 mt-2'>37</center></td>
                                <td><center className='mb-2 mt-2'>Ramjak (6318 Mtr.)  </center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2024</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>
                            </tr>


                            <tr >
                                <td > <center className='mb-2 mt-2'>38</center></td>
                                <td><center className='mb-2 mt-2'>Gorichen (6488 Mtr.)  </center></td>
                                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2024</center></td>
                                <td><center className='mb-2 mt-2'>Climbed</center></td>
                            </tr>

                        </tbody>
                    </Table>
                </div>
            </div>
            <br />
            <br />


        </div>
    )
}

export default MakOrganises