import React from 'react'
import thelu1 from "../images/Thelu 1.jpg";
import thelu2 from "../images/Thelu 2.jpg";
import gangstone from "../images/Gangstang 1 shortnote.jpg";
import tharkot from "../images/Tharkot 1.jpg"
import kamet1997 from "../images/Kamet 1997 1.jpg";
import kamet1996 from "../images/Kamet 1996 2.jpg";
import Chowkhamba from "../images/Chowkhamba-1 1.jpg";
import Bhrigupanth from "../images/Bhrigupanth 2.jpg";
import nandakot from "../images/Nandakot 1.jpg";
import shiva from "../images/Shiva.jpg";
import Deotibba from "../images/Deotibba 1.jpg";
import Indrasan from "../images/Indrasan 1.jpg";
import  Shivling from "../images/Shivling 1 -.jpg";
import Panwalidwar from "../images/Panwaliduar 1.jpg";
import Rubalkang from "../images/Rubalkang 1.JPG"
import ThalaySagar from "../images/Thalaysagar 1.jpg";
import Changuch from "../images/Chhanguch 2.JPG";


function ExpeditionShortnote() {
    return (
        <div>
            <div className="album py-2">
                <div className="container">
                    <div className='colorchaclate'>
                        <h1 style={{ color: "white" }} className='mt-2'><b>Expedition Short Note :</b></h1>
                    </div>
                </div>
            </div>
            <div className="album py-1">
                <div className="container">

                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <b>Mt. Thelu (6000 Mtr.) & Koteswar (6061 Mtr.) Expedition, 1987. </b>
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='colorchaclate'>
                                        <p>Area : Gangotri Region, Garhwal Himalaya</p>
                                        <h3><b>Members –</b></h3>
                                        <h6 className='justifynewtext'>1. Shri Pralay Majumder (Leader), 2. Shri Asok Roy, 3. Shri Subrata Bhattacharjee, 4. Shri Sanjib Chkaraborty, 5. Shri Netai Das, 6. Dr. Kiriti Tarafdar and 7. Ms. Basanti Londhre.
                                            The first expedition was organized in the month of May, 1987.
                                            Commencement of road head – trek start from Gangotri of Uttarkashi District on 10/5/1987.
                                            Number of camps en route to Base Camp : One transit camp at  Bhojbas had been established.
                                            Two more camps had been established before reaching summit of Mt. Thelu.  Summit reached by 1. Shri Pralay Majumder, 2. Shri Subrata Bhattacharjee, 3. Shri Sanjib Chkaraborty, 4. Ms. Basanti Londhre and 5. Shri Prem Singh on 15/5/1987.
                                            Mt. Koteswar (19880 ft.) summit reached by 1. Shri Pralay Majumder, 2. Shri Sanjib Chkaraborty, 3. Ms. Basanti Londhre and 4. Shri Prem Singh on 21/5/1987.
                                        </h6>

                                        <div className="album py-2">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-4 mt-3">
                                                        <img src={thelu1} className="card-img-top" alt="thelu" />
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <img src={thelu2} className="card-img-top" alt="thelu" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a className='mb-2' href='#' target="_blank" rel="noopener noreferrer"> More Details & View Photo <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                        </svg></a> <br />

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <b>Gangstang (6162 Mtr.) Expedition, 1990.</b>
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='colorchaclate'>
                                        <p>Area : Lahul Region, Himachal Himalaya.</p>
                                        <h3><b>Members –</b></h3>
                                        <h6 className='justify'>1. Shri Sourendra Banerjee (Leader), 2. Shri Asok Roy, 3. Shri Subrata Bhattacharjee, 4. Shri Sanjib Chakraborty, 5. Shri Basanta Singha Roy, 6. Shri Subhashis Chakraborty, 7. Shri Goutam Pal and 8. Shri Subhash Saha.
                                            The 2nd expedition was organized in the month of July, 1990.
                                            Commencement of trek from road head  - trek start from Keylong (Lahul & Spiti District of Himachal Pradesh) on 21/7/1990.
                                            Number of camps en route up to Base Camp.  One transit camp at an altitude of 3810 Mtr. on a nice alpine medow beside Billing Nallah had been established in between Keylong and Base Camp (4480 Mtr.) near Gangstang Glacier.
                                            Two more camps had been established before reaching summit of Mt. Gangstang.  Summit reached by 1. Shri Basanta Singha Roy, 2. Shri Lebodram Thakur and 3. Shri Kurumchand Thakur on 28/7/1990.
                                        </h6>
                                        <div className="col-md-4 mt-3">
                                            <img src={gangstone} className="card-img-top" alt="gangstone"/>
                                        </div>
                                        <a className='mb-2' href='Gangstang-1990' target="_blank" rel="noopener noreferrer"> More Details & View Photo <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                        </svg></a><br />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <b> Baljuri (5922 Mtr.)</b>
                                </button>
                            </h2>

                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='colorchaclate'>
                                        <p>Area : Kumaon Himalaya</p>
                                        <h3><b>Members – </b></h3>
                                        <h6 className='jusity'>1. Shri Basanta Singha Roy (Leader), 2. Shri Asok Roy (Dy. Leader), 3. Shri Subrata Bhattacharjee, 4. Shri Sanjib Chakraborty, 5. Dr. Partha Protim Roy (Medical Officer), 6. Shri Subhashis Chakraborty, 7. Shri Goutam Pal, 9. Shri Debasish Roy, 9. Shri Raju Roy and 10. Shri Subhash Saha.
                                            The third expedition was organized in the month of September, 1991.
                                            Commencement of trek from road head - trek start from Soang (Bageswar District, Uttarakhand) on 18/9/1991.
                                            Number of camps en route up to Base Camp.  Three transit camps had been established to reach Base Camp.  First camp at Loharkhet (1750 Mtr.) and stayed at PWD Bunglow, 2nd at Khati (2210 Mtr.) and stayed at Hotel and third camp at Dwali (2576 Mtr.) and here stayed at Nigam  Bunglow.  Base Camp (4120 Mtr.) established 1 k.m. ahead of Pindari Glacier on a beautiful meadow on the true right bank of Pindai river
                                            Two more camps had been established before reaching summit Mt. Baljuri.  Summit reached by 1. Shri Basanta Singha Roy, 2. Shri Asok Roy, 3. Shri Sanjib Chakraborty, 4. Shri Debasish Roy, 5. Shri Raju Roy, 6. Shri Subhas Saha, 7. Shri Subrata Bhattacharjee, 8. Shri Rup Singh and 9. Shri Gopal Singh on 25/9/1991 and 26/9/1991.
                                        </h6>
                                      
                                        <a className='mb-2' href='Baljuri-1991' target="_blank" rel="noopener noreferrer"> More Details & View Photo <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                        </svg></a>
                                        <br />

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <b>Un-named Peak (6352 Mtr. - Seta Glacier).</b>
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='colorchaclate'>
                                        <p>Area : Gangotri Glacier, Garhwal Himalaya.</p>
                                        <h3><b>Members –</b></h3>
                                        <h6 className='jusity' >1. Shri Basanta Singha Roy (Leader), 2. Shri Asok Roy (Dy. Leader), 3. Dr. Partha Protim Roy (Medical Officer), 4. Shri Raju Roy, 5. Shri Nirmal Mukherjee and 6. Shri Ajay Krishna Bhattacharjee.
                                            The fourth expedition was organized in the month of August – September, 1992.
                                            Commencement of trek from road head – Trek start from Gangotri (Uttarkashi District) on 21/8/1992.
                                            Number of camps en route to Base Camp : One transit camp at Bhojbas had been established to reach Base Camp i.e. Nandanvan (4360 Mtr.).
                                            4 more camps had been established before reaching summit Un-named peak.  Summit reached by 1. Shri Basanta Singha Roy,  and 2. Shri Rajbahadur Sahi on 01/9/1992.
                                        </h6>
                                        <a className='mb-2' href='Setaglacier-1992' target="_blank" rel="noopener noreferrer"> More Details & View Photo <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                        </svg></a>

                                        <br />


                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    <b>Shrikailash (6932 Mtr.) </b>
                                </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='colorchaclate'>
                                        <p>Area : Gangotri Glacier, Garhwal Himalaya.</p>
                                        <h3><b> Members –</b></h3>
                                        <h6 className='jusity'>1. Shri Basanta Singha Roy (Leader), 2. Shri Asok Roy (Dy. Leader), 3. Sanjib Chakraborty, 4. Shri Debasish Roy, 5. Shri Subhas Saha, 6. Shri Raju Roy, 7,  Shri Nirmal Mukherjee, 8. Shri Ajay Krishna Bhattacharjee, and 9, Shri Swagatam Das.
                                            The fifth expedition was organized in the month of September, 1993.
                                            Commencement of trek from road head – Trek start from Gangotri (Uttarkashi District) on 05/9/1993.
                                            Number of camps en route to Base Camp : One transit camp had been established at Bhojbas to reach Base Camp at Thelu Bamak (Raktvaran Glacier – 4527 Mtr.).
                                            7 more camps had been established before reaching summit of Mt. Srikailash due to heavy fresh snowfall.  Summit reached by 1. Shri Basanta Singha Roy, 2. Shri Rajbahadur Sahi and Dharamraj on 20/9/1993.
                                        </h6>
                                        <a className='mb-2' href='Srikailash-1993' target="_blank" rel="noopener noreferrer"> More Details & View Photo <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                        </svg></a>
                                        <br />


                                    </div>

                                </div>
                            </div>
                        </div>



                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSix">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    <b>Tharkot (6099 Mtr.) & Bhanoti (5816 Mtr.)</b>
                                </button>
                            </h2>
                            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingFivSix" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='colorchaclate'>
                                        <p>Area : Kumaon Himalaya</p>
                                        <h3><b> Members –</b></h3>
                                        <h6 className='jusity'>1. Shri Basanta Singha Roy (Leader), 2. Shri Asok Roy (Dy. Leader), 3. Shri Subrata Bhattacharjee, 4. Shri Raju Roy, 5. Shri Nirmal Mukherjee  and 6.  Shri Swagatam Das.
                                            The sixth expedition was organized in the month of August - September, 1994.
                                            Commencement of trek from road head - trek start from Soang (Bageswar District, Uttarakhand) on 31/8/1994.
                                            Number of camps en route up to Base Camp.  Four transit camps had been established to reach Base Camp (Sukhram Cave).  First camp at Loharkhet (1750 Mtr.) and stayed at PWD Bunglow, 2nd at Khati (2210 Mtr.) and stayed at PWD Bunglow, third camp at Jatoli Village (2576 Mtr.) and here stayed at Rup Singh’s house (Team’s Guide) and fourth camp at Sundardunga (3200 Mtr.) and here the team stayed at a temporary Hut.  Base Camp (3965 Mtr.) reached on 04/9/1994.
                                            Three more camps had been established before reaching summit of Tharkot.  Summit reached by 1. Shri Basanta Singha Roy, 2. Shri Raju Roy, 3. Shri Swagatam Das, 4. Shri Rajbahadur Sahi and Shri Rup Singh on 12/9/1994.
                                            Mt. Bhanoti summit reached by 1. Shri Asok Roy, 2. Shri Subrata Bhattacharjee, 3. Shri Nirmal Mukherjee and 4. Shri Rup Singh on 13/9/1994 from the same Camp – I of Tharkot peak.

                                        </h6>
                                        <div className="col-md-4 mt-3">
                                        <img src={tharkot} className="card-img-top" alt="tharkot"/>
                                        </div>
                                        <a className='mb-2' href='Tharkot-1994' target="_blank" rel="noopener noreferrer"> More Details & View Photo <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                        </svg></a>
                                        <br />


                                    </div>

                                </div>
                            </div>
                        </div>





                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSeven">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    <b>CB – 11 (6045 Mtr.) </b>
                                </button>
                            </h2>
                            <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='colorchaclate'>
                                        <p>Area : Himachal Himalaya.</p>
                                        <h3><b> Members –</b></h3>
                                        <h6 className='jusity'>1. Shri Basanta Singha Roy (Leader), 2. Shri Asok Roy (Dy. Leader), 3. Shri Goutam Pal, 4. Shri Raja Roy and Shri Pal.
                                            The seventh expedition was organized in the month of August – September, 1995.
                                            Commencement of trek from road head - trek start from Batal of Kulu District of Himachal Pradesh on 25/8/1995.
                                            Number of camps en route up to Base Camp.  One transit camp had been established  to reach Base Camp.
                                            One more camp had been established before reaching summit of CB – 11.  Summit reached by Shri Basanta Singha Roy, 2. Shri Somdeb Sharma and 3. Shri Tikamram Thakur on 28/7/1995.

                                        </h6>
                                        <a className='mb-2' href='#' target="_blank" rel="noopener noreferrer"> More Details & View Photo <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                        </svg></a>
                                        <br />


                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingEight">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    <b>Kamet – 7756 Mtr. (Third Highest Peak in India). </b>
                                </button>
                            </h2>
                            <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='colorchaclate'>
                                        <p>Area : Garhwal Himalaya.</p>
                                        <h3><b> Members –</b></h3>
                                        <h6 className='jusity'>1. Shri Basanta Singha Roy (Leader), 2. Shri Asok Roy (Dy. Leader), 3. Shri Subrata Bhattacharjee, 4. Dr. Partha Protim Roy (Medical Officer), 5. Shri Subhashis Chakraborty, 6. Shri Goutam Pal, 7. Shri Debasish Roy, 8. Ajay Krishna Bhattacharjee, 9. Goutam Pal (Junior), 10. Shri Dipen Samanta and 11. Shri Arupam Das.
                                            The eighth expedition was organized in the month of September – October, 1996.
                                            Commencement of trek from road head – trek started  from Malari,  Joshimath, Chamoli Distrist of Uttarakhand on 10/9/1996.
                                            Number of camps en route up to Base Camp. Three transit camps had been established before reaching Base Camp.
                                            5  more camps had been established but the team could not reached the summit due to blizzard.  The team forced to abandon the expedition.  They reached at an altitude of 6860 Mtr. on 3/10/1996.

                                        </h6>
                                        <div className="col-md-4 mt-3">
                                        <img src={kamet1996} className="card-img-top" alt="kamet1996"/>
                                        </div>
                                      
                                        <a className='mb-2' href='#' target="_blank" rel="noopener noreferrer"> More Details & View Photo <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                        </svg></a>
                                        <br />


                                    </div>

                                </div>
                            </div>
                        </div>



                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingNine">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    <b>Kamet – 7756 Mtr. (Third Highest Peak in India). </b>
                                </button>
                            </h2>
                            <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='colorchaclate'>
                                        <p>AArea : Garhwal Himalaya.</p>
                                        <h3><b> Members –</b></h3>
                                        <h6 className='jusity'>1. Shri Basanta Singha Roy (Leader), 2. Shri Asok Roy (Dy. Leader), 3. Shri Subhashis Chakraborty, 4. Shri Tamal Bhattacharjee, 5. Shri Debasish Biswas and 6. Shri Sourav Sinchan Mondal.
                                            The ninth expedition was organized in the month of May – June, 1997.
                                            Commencement of trek from road head – trek started  from Malari,  Joshimath, Chamoli Distrist of Uttarakhand on 12/5/1997.
                                            Number of camps en route up to Base Camp. Two transit camps had been established  before reaching Base Camp.
                                            6  more camps had been established before reaching summit of Mt. Kamet.  Summit reached by 1. Shri Basanta Singha Roy, 2. Shri Debasish Biswas, 3. Shri Aung Tenzi Sherpa and 3. Shri Thupka Sherpa on 05/6/1997.

                                        </h6>
                                        <div className="col-md-4 mt-3">
                                        <img src={kamet1997} className="card-img-top" alt="kamet1997"/>
                                        </div>
                                        <a className='mb-2' href='Kamet-1997' target="_blank" rel="noopener noreferrer"> More Details & View Photo <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                        </svg></a>
                                        <br />


                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTen">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                    <b>Chowkhamba-I (7138 Mtr.) </b>
                                </button>
                            </h2>
                            <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='colorchaclate'>
                                        <p>Area : Garhwal Himalaya</p>
                                        <h3><b> Members –</b></h3>
                                        <h6 className='jusity'>1. Shri Basanta Singha Roy (Leader), 2. Shri Asok Roy (Dy. Leader), 3. Shri Ajay Krishna Bhattacharjee, 4. Shri Debasish Biswas, 5. Shri Sourav SinchanMondal, 6. Shri TamalBhattacharjee, 7. Shri Swarup Karfa, 8. Shri Bivas Sarkar and 9. Shri Jyotirmoy Sarkar.
                                            The tenth expedition was organized in the month of August – September, 1998.
                                            Commencement of trek from road head – trek started  from Mana village, Badrinath, Joshimath, Chamoli Distrist of Uttarakhand on 19/8/1998.
                                            Number of camps en route up to Base Camp. Two transit camps had been established  before reaching Base Camp.
                                            4  more camps were established before reaching summit of Mt. Chowkhamba – I. Summit reached by 1. Shri Basanta Singha Roy, 2. Shri Aung Tenzi Sherpa and 3. Shri Thendup Sherpa on 04/9/1998.

                                        </h6>
                                        <div className="col-md-4 mt-3">
                                        <img src={Chowkhamba} className="card-img-top" alt="Chowkhamba"/>
                                        </div>
                                        <a className='mb-2' href='Choukhamba-1998' target="_blank" rel="noopener noreferrer"> More Details & View Photo <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                        </svg></a>
                                        <br />


                                    </div>

                                </div>
                            </div>
                        </div>



                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingEleven">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                    <b>Bhrigupanth (6772 Mtr.) </b>
                                </button>
                            </h2>
                            <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='colorchaclate'>
                                        <p>Area : Garhwal Himalaya</p>
                                        <h3><b> Members –</b></h3>
                                        <h6 className='jusity'>1. Shri Basanta Singha Roy (Leader), 2. Shri Asok Roy, 3. Shri Ajay Krishna Bhattacharjee, 4. Shri Subhasish Chakraborty, 5. Shri Bivas Sarkar, 6. Shri Chandan Banerjee, 7. Shri Bhupendranath Pal and 8. Shri Swarup Kharfa.
                                            The eleventh expedition was organized in the month of May, 1999.
                                            Commencement of trek from road head – trek started  from Gangotri, Uttarkashi District, Uttarakhand on 05/5/1999.
                                            Number of camps en route up to Base Camp. One transit camp had been established between Bhojkharak and Kedarkharak before reaching Base Camp i.e. Kedartal.
                                            4  more camps were established before reaching summit of Mt. Bhrigupanth. Summit reached by 1. Shri Basanta Singha Roy, 2. Shri Swarup Kharfa, 3. Shri Pemba Sherpa and 4. Shri Thendup Sherpa on 20/5/1999.


                                        </h6>
                                        <div className="col-md-4 mt-3">
                                        <img src={Bhrigupanth} className="card-img-top" alt="Bhrigupanth"/>
                                        </div>
                                        <a className='mb-2' href='Bhrigupanth-1999' target="_blank" rel="noopener noreferrer"> More Details & View Photo <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                        </svg></a>
                                        <br />


                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwelve">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                    <b>Nandakot (6861 Mtr.) </b>
                                </button>
                            </h2>
                            <div id="collapseTwelve" className="accordion-collapse collapse" aria-labelledby="headingTwelve" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='colorchaclate'>
                                        <p>Area : Garhwal Himalaya</p>
                                        <h3><b> Members –</b></h3>
                                        <h6 className='jusity'>1. Shri Basanta Singha Roy (Leader), 2. Shri Subrata Brahma, 3. Shri Debasish Biswas, 4. Shri Sourav Sinchan Mondal, 5. Shri Jyotirmoy Sarkar, 6. Shri Subhasish Chakraborty and 7. Shri Bivas Sarkar.
                                            The twelfth expedition was organized in the month of October, 2001.
                                            Commencement of trek from road head – trek started from Munsiary, District Pithgarh, Uttarakhand on 07/10/2000.

                                            Number of camps en route up to Base Camp: Five transit camps had been established before reaching Base Camp.

                                            2 more camps had been established to reach summit of Mt. Nandakot.  But the team could not reached the summit due to un-favourable condition of snow.


                                        </h6>
                                        <div className="col-md-4 mt-3">
                                        <img src={nandakot} className="card-img-top" alt="nandakot"/>
                                        </div>
                                        <a className='mb-2' href='Nandakot-2000' target="_blank" rel="noopener noreferrer"> More Details & View Photo <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                        </svg></a>
                                        <br />


                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThirteen">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                    <b>Shiva (6142 Mtr.) </b>
                                </button>
                            </h2>
                            <div id="collapseThirteen" className="accordion-collapse collapse" aria-labelledby="headingThirteen" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='colorchaclate'>
                                        <p>Area : Himachal Himalaya</p>
                                        <h6>Mt. Shiva is situated in the Pangi Valley of Chamba District of Himachal Pradesh.  Latitude and longitude of this peak is 76  35’ E and 33  6’ N respectively.  The local people regarded this peak as a sacred mountain and often refer to it as Shibji or Kailash.
                                            Only the first Lady Everest Climber, Junko Tawi of Japan climbed this peak in the year 1988.  Several attempts were also made but no one could reach the summit after the first ascent.
                                        </h6>
                                        <h3><b> Members –</b></h3>
                                        <h6 className='jusity'>1. Shri Basanta Singha Roy (Leader), 2. Shri Asok Roy, 3. Shri Subhasis Chakraborty, 4. Shri Ajay Bhattacherjee, 5. Shri Debasis Biswas, 6. Shri Subrata Brahma, 7. Shri Biswanath Saha and 8. Shri Mithun Bhowmick
                                            The thirteenth expedition was organized in the month of August - September, 2002.
                                            Commmence of journey from road head – trek started on 20/8/2002 from Cherry (240 K.M. from Manali - 14 k.m. before Killer, Head Quarter of Pangi Valley), Chamba District.
                                            One transit camp had been established at Parmar village (3200 M) before reaching Base Camp.  The team took 5 hours to reach Parmar village from Cherry.  They established Base Camp (4000 M) before the snout of south glacier on a flat ground which was covered with knee high grass.  The team took 4 hours to reach Base Camp.
                                            3  more camps had been established before reaching summit of Mt. Shiva. Summit reached by 1. Shri Basanta Singha Roy, 2. Shri Debasish Biswas, 3. Shri Sange Sherpa and 4. Shri Dupka Sherpa on 30/8/2002.  (First Indian Ascent).


                                        </h6>
                                        <div className="col-md-4 mt-3">
                                        <img src={shiva} className="card-img-top mb-2" alt="shiva"/>
                                        </div>
                                        <a className='mb-2' href='Shiva-2002' target="_blank" rel="noopener noreferrer"> More Details & View Photo <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                        </svg></a>
                                        <br />


                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFourteen">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                    <b>Deotibba (6001 Mtr.)</b>
                                </button>
                            </h2>
                            <div id="collapseFourteen" className="accordion-collapse collapse" aria-labelledby="headingFourteen" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='colorchaclate'>
                                        <p>Area : Himachal Himalaya.</p>
                                        <h3><b> Members –</b></h3>
                                        <h6 className='jusity'>1. Shri Basanta Singha Roy (Leader), 2. Shri Subrata Brahma , 3. Shri Ajay Krishna Bhattacharjee, 4. Shri Goutam Pal and Shri Rabi Shankar Karmakar.
                                            The fourteenth expedition was organized in the month of September, 2003.
                                            Commencement of trek from road head – trek started on 29/8/2003 from Jagatsukh Village (8 K.M. from Manali), Kullu District, Himachal Pradesh.

                                            Number of camps en route up to Base Camp: Two transit camps had been established i.e.  Chikka  and Seri (3665 M)  before reaching Base Camp.

                                            2  more camps had been established before reaching summit of Mt. Deotibba.  Summit reached by 1. Shri Basanta Singha Roy and 2. Shri Thupka Sherpa on 18/9/2003.

                                        </h6>
                                        <div className="col-md-4 mt-3">
                                        <img src={Deotibba} className="card-img-top mb-2" alt="Deotibba"/>
                                        </div>
                                        <a className='mb-2' href='#' target="_blank" rel="noopener noreferrer"> More Details & View Photo <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                        </svg></a>
                                        <br />


                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFifteen">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                    <b>Indrasan (6221 Mtr.) </b>
                                </button>
                            </h2>
                            <div id="collapseFifteen" className="accordion-collapse collapse" aria-labelledby="headingFifteen" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='colorchaclate'>
                                        <p>Area : Himachal Himalaya</p>
                                        <h3><b> Members –</b></h3>
                                        <h6 className='jusity'>1. Shri Basanta Singha Roy (Leader), 2. Shri Subrata Brahma, 3. Shri Asim Mondal and 4.Shri Prasit Biswas.
                                            The fifteenth expedition was organized in the month of August, 2004.
                                            Commencement of trek from /road head – trek started on 19/8/2004 from Jagatsukh Village (8 K.M. from Manali), Kullu District, Himachal Pradesh.

                                            Number of camps en route up to Base Camp: Two transit camps had been established i.e  Khanol  and Seri (3665 M)  before reaching Base Camp.

                                            3  More camps were established before reaching summit of Mt. Indrasan.  Summit reached by 1. Shri Basanta Singha Roy, 2. Shri Thupka Thsring Sherpa, 3. Shri Pemba Sherpa and 4. Shri Sange Sherpa on 30/8/2004. (First Indian Ascent).
                                        </h6>
                                        <div className="col-md-4 mt-3">
                                        <img src={Indrasan} className="card-img-top mb-2" alt="Indrasan"/>
                                        </div>
                                        <a className='mb-2' href='Indrasan-2004' target="_blank" rel="noopener noreferrer"> More Details & View Photo <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                        </svg></a>
                                        <br />


                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSixteen">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                    <b>Shivling – (6543 Mtr.) </b>
                                </button>
                            </h2>
                            <div id="collapseSixteen" className="accordion-collapse collapse" aria-labelledby="headingSixteen" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='colorchaclate'>
                                        <p>Area : Garhwal Himalaya</p>
                                        <h3><b> Members –</b></h3>
                                        <h6 className='jusity'>
                                            1. Shri Basanta Singha Roy (Leader), 2. Shri Debasis Biswas (Dy. Leader), 3. Shri Asok Roy, 4. Shri Tapan Roy, 5. Shri Subrata Brahma, 6. Shri Bivas Sarkar, 7. Shri Asim Mondal and 8. Shri Bimalesh Das
                                            The sixteenth expedition was organized in the month of August, 2005.
                                            Commencement of trek from road head – trek started 17/8/2005 from Gangotri, Uttarkashi District, Uttarakhand.
                                            Number of camps en route up to Base Camp. One transit camp had been established at Bhojbas before reaching Base Camp.
                                            3  More camps had been established before reaching summit of Mt. Shivling.  Summit reached by 1. Shri Basanta Singha Roy, 2. Shri Debasis Biswas, 3. Shri Pasang Sherpa and 4. Shri Pemba Sherpa on 30/8/2005.


                                            3  More camps were established before reaching summit of Mt. Indrasan.  Summit reached by 1. Shri Basanta Singha Roy, 2. Shri Thupka Thsring Sherpa, 3. Shri Pemba Sherpa and 4. Shri Sange Sherpa on 30/8/2004. (First Indian Ascent).
                                        </h6>
                                        <div className="col-md-4 mt-3">
                                        <img src={Shivling} className="card-img-top mb-2" alt="Shivling"/>
                                        </div>
                                        <a className='mb-2' href='Shivling-2005' target="_blank" rel="noopener noreferrer"> More Details & View Photo <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                        </svg></a>
                                        <br />
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSeventeen">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                    <b>Panwalidwar (6663 Mtr.) </b>
                                </button>
                            </h2>
                            <div id="collapseSeventeen" className="accordion-collapse collapse" aria-labelledby="headingSeventeen" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='colorchaclate'>
                                        <p>Area : Kumaon Himalaya</p>
                                        <h3><b> Members –</b></h3>
                                        <h6 className='jusity'>1. Shri Debasis Biswas (Leader), 2. Shri Basanta Singha Roy ( Dy. Leader), 3. Shri Ajay Krishna Bhattacharjee, 4. Shri Tapan Roy, 5. Shri Subrata Brahma, 6. Shri Buddhadeb Haldar, 7. Shri Bijay Sarkar, 8. Shri Supriyo Chitra and 9. Shri Souvik Sarkar.
                                            The seventh expedition was organized in the month of August, 2006.
                                            Commencement of trek from road head – trek started from Soang on 08/8/2006 from road head Song, Bageswar, Uttarakhand.
                                            Number of camps en route up to Base Camp. Three transit camp had been established at Loharkhet (1750 M) on 08/08/2006, Khati (2210 M) on 09/08/2006 and Dwali  (2734 M) on 10/08/2006 before reaching Base Camp (4120 Mtr.)
                                            3  More camps had been established before reaching summit of Mt. Panwalidwar.  Summit reached by 1. Shri Basanta Singha Roy, 2. Shri Debasis Biswas, 3. Shri Pasang Sherpa and 4. Shri Pemba Sherpa on 22/8/2006
                                            .
                                        </h6>
                                        <div className="col-md-4 mt-3">
                                        <img src={Panwalidwar} className="card-img-top mb-2" alt="Panwalidwar"/>
                                        </div>
                                        <a className='mb-2' href='panwaliduar-2006' target="_blank" rel="noopener noreferrer"> More Details & View Photo <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                        </svg></a>
                                        <br />


                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingEighteen">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                                    <b>Rubalkang (6187 Mtr.) </b>
                                </button>
                            </h2>
                            <div id="collapseEighteen" className="accordion-collapse collapse" aria-labelledby="headingEighteen" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='colorchaclate'>
                                        <p>Area : Himachal Himalaya</p>
                                        <h3><b> Members –</b></h3>
                                        <h6 className='jusity'>
                                            It was a joint venture of Kewkradong of Bangladesh and Mountaineers’ Association of Krishnanagar of India.
                                            The team members of Moountaineers’ Association of Krishnagar, West Bengal are – 1. Shri Basanta Singha Roy (Leader), 2. Shri Asok Roy, 3. Shri Ajay Krishna Bhattacharjee, 4. Shri Tapan Roy, 5. Shri Subrata Brahma, 6. Shri Buddhadeb Haldar, 7. Shri Shri Supriyo Chitra 8. Shri Biswanath Saha, 9. Shri Sourav Sinchan Mondal and 10. Dr. Sanjib Raha.
                                            The team members of Kewkradong, Dhaka, Banglesh are - 1. SM. Muntasir Mamun, 2. H. Md. Shirajul Haq, 3. Saad Bin Hossain and 4. Md. Ataur Rahman Khan.
                                            Liaison Officer : Shri Debasish Biswas.
                                            The eighteenth expedition was organized in the month of May, 2007.
                                            Commencement of trek from road head – trek started from Barseni, Kulu District on 20/5/1007.
                                            Number of camps en route up to Base Camp. Three transit camps had been established at Khirganga (1750 M) on 21/5/2007, Tundabhuj (2210 M) on 22/5/2007 and Thakurkuon  (2734 M) on 23/5/2007 before reaching Base Camp (4120 Mtr.)
                                            3  More camps had been established before reaching summit of Mt. Rubalkang.  Summit reached by 1. Shri Basanta Singha Roy, 2. Shri Biswanath Saha, 3. Shri Muntasir Mamun, 4. Shri Shirajul Haq, 5. Shri Saad Bin Hussain, 6. Shri Thendup Sherpa and 7. Shri Pema Sherpa on 30/5/2007.
                                            Again 1. Shri Debasish Biswas, 2. Shri Ajay Krishna Bhattacharjee, 3. Shri Sourav Sinchan Mondal, 4. Shri Subrata Brahma, 5. Shri Buddhadeb Haldar, 6. Shri Thendup Sherpa and 7. Shri Pema Sherpa reached the summit on 31/5/2007.

                                        </h6>
                                        <div className="col-md-4 mt-3">
                                        <img src={Rubalkang} className="card-img-top mb-2" alt="Rubalkang"/>
                                        </div>
                                        <a className='mb-2' href='Kulumakalu-rubalkang-2007' target="_blank" rel="noopener noreferrer"> More Details & View Photo <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                        </svg></a>
                                        <br />


                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingNineteen">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
                                    <b>Thalay Sagar (6904 Mtr.) </b>
                                </button>
                            </h2>
                            <div id="collapseNineteen" className="accordion-collapse collapse" aria-labelledby="headingNineteenifteen" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='colorchaclate'>
                                        <p>Area : Garhwal Himalaya</p>
                                        <h3><b> Members –</b></h3>
                                        <h6 className='jusity'>
                                            1. Shri Basanta Singha Roy (Leader), 2. Shri Asok Roy, 3. Shri Ajay Krishna Bhattacharjee, 4. Shri Tapan Roy, 5. Shri Subrata Brahma, 6. Shri Biswanath Saha, 7. Shri Asim Kumar Mondal and 8. Shri Ranjan Kumar and 9. Shri Bijoy Kanti Sarkar.
                                            The nineteenth expedition was organized in the month of August – September, 2008.
                                            Commencement of trek from road head – trek started 15/8/2008 from Gangotri, Uttarkashi District, Uttarakhand.
                                            Number of camps en route up to Base Camp. One transit camp had been established in between Bhojkharak and Kedarkharak before reaching Base Camp i.e. Kedartal.
                                            3  More camps had been established before reaching summit of Mt. Thalay Sagar.  Summit reached by 1. Shri Basanta Singha Roy, 2. Shri Pasang Sherpa, 3. Shri Pemba Sherpa and 4. Shri Phurba Gyalgen on 01/9/2008. (This was first Indian ascent).

                                        </h6>
                                        <div className="col-md-4 mt-3">
                                        <img src={ThalaySagar} className="card-img-top mb-2" alt="ThalaySagar"/>
                                        </div>
                                        <a className='mb-2' href='thalaysagar-2008' target="_blank" rel="noopener noreferrer"> More Details & View Photo <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                        </svg></a>
                                        <br />


                                    </div>

                                </div>
                            </div>
                        </div>




                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwenty">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
                                    <b>Changuch (6322 Mtr.) </b>
                                </button>
                            </h2>
                            <div id="collapseTwenty" className="accordion-collapse collapse" aria-labelledby="headingTwenty" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='colorchaclate'>
                                        <p>Area : Kumaon Himalaya</p>
                                        <h3><b> Members –</b></h3>
                                        <h6 className='jusity'>1. Shri Basanta Singha Roy (Leader), 2. Shri Sanjib Chakraborty, 3. Shri Ajay Krishna Bhattacharjee, 4. Shri Subrata Brahma, 5. Shri Asim Kumar Mondal, 6. Shri Supriyo Chitra, 7. Shri Bejoy Kanti Sarkar.
                                            The twentieth expedition was organised in the month of August - September, 2009. Commencement of trek from road head - trek started on 18/8/2009 from Song, Bageswar District of Uttarakhand.
                                            3 transit camps established at Loharkhet, Khati & Phurkia before reaching Base Camp at Martoli Kharak (Beside Pindari '0' Point).
                                            4 More camps had been established. Summit was not possible due to climbing difficulties. Then 4 member and 4 Sherpa crossed Traill's Pass on 30/8/2009. Members are :1. Basanta Singha Roy, 2. Sanjib Chakraborty, 3. Subrata Brahma and 4. Asim Kumar Mondal. Sherpas are Pasang Sherpa, Pemba Sherpa, Phurba Sherpa and Lilabati Rai (Cook).

                                        </h6>
                                        <div className="col-md-4 mt-3">
                                        <img src={Changuch} className="card-img-top mb-2" alt="Changuch"/>
                                        </div>
                                        <a className='mb-2' href='Changuch-2009' target="_blank" rel="noopener noreferrer"> More Details & View Photo <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                        </svg></a>
                                        <br />


                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSix">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    <b>6.	KANYA SIDDHI : কন্যা সিদ্ধি </b>
                                </button>
                            </h2>
                            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <h6>1 মাস থেকে 10 বছর বয়স বাচ্চা মেয়ে   এর  জন্য
                                        স্পেশাল স্কিমস. <br />
                                        মেয়াদপূর্তিতে আকর্ষণীয় সুদের হার (সর্বোচ্চ মেয়াদপূর্তির সময়কাল 12 বছর
                                    </h6>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <br />


        </div>
    )
}

export default ExpeditionShortnote